var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      id: "sidebar-form-tiered-price",
      title: _vm.isEdit
        ? _vm.$t("Editar preço escalonado")
        : _vm.$t("Adicionar preço escalonado"),
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      width: "600px",
    },
    on: { save: _vm.onSaveItem, hidden: _vm.hide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              { ref: "itemSidebarForm", attrs: { name: "itemSidebarForm" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-store-combo", {
                          attrs: {
                            id: "filters-store",
                            "only-active-options": true,
                            required: false,
                            disabled: _vm.isEditingMode,
                            placeholder: _vm.$t("Todas"),
                          },
                          model: {
                            value: _vm.itemForm.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.itemForm, "storeId", $$v)
                            },
                            expression: "itemForm.storeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tiered_price_sidebar-price-table",
                            type: "vue-select",
                            validation: "required",
                            disabled: _vm.isEditingMode,
                            label: _vm.$t("Tabela de preço"),
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.priceTableOptions,
                          },
                          model: {
                            value: _vm.itemForm.priceTableId,
                            callback: function ($$v) {
                              _vm.$set(_vm.itemForm, "priceTableId", $$v)
                            },
                            expression: "itemForm.priceTableId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("FormulateInput", {
                  ref: "priceGroup",
                  attrs: {
                    name: "tieredPrices",
                    type: "group",
                    repeatable: true,
                    "add-label": _vm.$t("Adicionar faixa"),
                  },
                  on: { repeatableRemoved: _vm.handleRemove },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var index = ref.index
                        return [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id:
                                        "tiered_price_sidebar-quantity-from-" +
                                        index,
                                      name: "quantityFrom",
                                      type: "text-number",
                                      disabled: index > 0,
                                      value: _vm.getQuantityFrom(index),
                                      label: _vm.$t("Quantidade inicial"),
                                      validation: "required",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id:
                                        "tiered_price_sidebar-quantity-to-" +
                                        index,
                                      name: "quantityTo",
                                      type: "text-number",
                                      label: _vm.$t("Quantidade final"),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.updateNextQuantityFrom(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "tiered_price_sidebar-price-" + index,
                                      name: "price",
                                      type: "text-number",
                                      currency: "R$",
                                      precision: 2,
                                      validation: "required",
                                      label: _vm.$t("Preço"),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.validatePrice(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.tieredPrices,
                    callback: function ($$v) {
                      _vm.tieredPrices = $$v
                    },
                    expression: "tieredPrices",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }