<template>
  <e-sidebar
    id="sidebar-form-tiered-price"
    :title="isEdit ? $t('Editar preço escalonado') : $t('Adicionar preço escalonado')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="600px"
    @save="onSaveItem"
    @hidden="hide"
  >
    <template #content>
      <FormulateForm
        ref="itemSidebarForm"
        name="itemSidebarForm"
      >
        <b-row>
          <b-col md="12">
            <e-store-combo
              id="filters-store"
              v-model="itemForm.storeId"
              :only-active-options="true"
              :required="false"
              :disabled="isEditingMode"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="tiered_price_sidebar-price-table"
              v-model="itemForm.priceTableId"
              type="vue-select"
              class="required"
              validation="required"
              :disabled="isEditingMode"
              :label="$t('Tabela de preço')"
              :placeholder="$t('Selecione')"
              :options="priceTableOptions"
            />
          </b-col>
        </b-row>
        <FormulateInput
          v-slot="{ index }"
          ref="priceGroup"
          v-model="tieredPrices"
          name="tieredPrices"
          type="group"
          :repeatable="true"
          :add-label="$t('Adicionar faixa')"
          @repeatableRemoved="handleRemove"
        >
          <b-row>
            <b-col md="4">
              <FormulateInput
                :id="`tiered_price_sidebar-quantity-from-${index}`"
                name="quantityFrom"
                type="text-number"
                :disabled="index > 0"
                :value="getQuantityFrom(index)"
                class="required"
                :label="$t('Quantidade inicial')"
                validation="required"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                :id="`tiered_price_sidebar-quantity-to-${index}`"
                name="quantityTo"
                type="text-number"
                :label="$t('Quantidade final')"
                @input="updateNextQuantityFrom(index)"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                :id="`tiered_price_sidebar-price-${index}`"
                name="price"
                type="text-number"
                currency="R$"
                :precision="2"
                validation="required"
                class="required"
                :label="$t('Preço')"
                @input="validatePrice(index)"
              />
            </b-col>
          </b-row>
        </FormulateInput>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar, EStoreCombo } from '@/views/components'
import { authorizationRules, discountTypes, formulateHelper } from '@/mixins'

const getInitialTieredPriceForm = () => ({
  id: null,
  quantityFrom: null,
  quantityTo: null,
  price: null,
})

const getInitialItemForm = () => ({
  storeId: null,
  priceTableId: null,
})

const SAFE_JAVASCRIPT_MAX_INTEGER_VALUE = Number.MAX_SAFE_INTEGER
const SAFE_MAX_INPUT_VALUE = 9999999

export default {
  components: { BRow, BCol, ESidebar, EStoreCombo },

  mixins: [discountTypes, formulateHelper, authorizationRules],

  props: {
    storeId: {
      type: [String, Number],
      default: null,
    },
    priceTableId: {
      type: [String, Number],
      default: null,
    },
    isEditingMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      itemForm: getInitialItemForm(),
      showSidebar: false,
      fetching: false,
      saving: false,
      tieredPrices: [getInitialTieredPriceForm()],
      removedPrices: [],
    }
  },

  computed: {
    ...mapGetters('pages/catalog/products/productMaintain', ['priceTableOptions']),
    ...mapState('pages/catalog/products/productMaintain', ['priceTables']),
    ...mapState('pages/catalog/products/productMaintain', {
      tieredPricesStore: 'tieredPrices'
    }),
    isEdit() {
      return !!this.itemForm.id || !!this.itemForm.localId
    },
    activeTieredPrices: {
      get() {
        // Retorna apenas os itens que não foram deletados
        return this.tieredPrices.filter(item => !item.isDeleted)
      },
      set(newPrices) {
        // Atualiza os valores existentes, sem remover fisicamente os itens
        this.tieredPrices = newPrices
      },
    },
  },

  methods: {
    ...mapActions('pages/catalog/products/productMaintain', ['setTieredPrice']),
    async show(item) {
      this.cleanSidebar()
      this.showSidebar = true
      this.isEditingMode = false

      if (item) {
        this.itemForm = { ...item }
        this.tieredPrices = item.prices
        this.isEditingMode = true
      }
    },

    hide() {
      this.cleanSidebar()
      this.showSidebar = false
    },

    cleanSidebar() {
      this.itemForm = getInitialItemForm()
      this.tieredPrices = [getInitialTieredPriceForm()]
    },

    async onSaveItem() {
      try {
        this.saving = true
        this.$refs.itemSidebarForm.showErrors()

        if (this.$refs.itemSidebarForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }

        const errors = this.tieredPrices.map((_, index) => {
          const result = this.validatePrice(index)
          if(!result) return true

          return false
        })

        if(errors.includes(true)) return

        if (this.isEdit) {
          this.$emit('update', this.itemForm)
        } else {
          const tieredPrices = this.buildTieredPrices()
          const problematicPriceRanges = this.getProblematicPriceRanges(tieredPrices)

          if (problematicPriceRanges.length > 0) {
            this.showWarningForProblematicRanges(problematicPriceRanges)
            return
          }

          const hasSomeDeletedPrice = this.tieredPricesStore
            .map(price => price.isDeleted)
            .filter(boolean => boolean === true)

          if(!hasSomeDeletedPrice.includes(true)) {
            this.setTieredPrice(tieredPrices)
          } else {
            this.updateTieredPrices()
          }

          this.$emit('add', this.itemForm)
          this.cleanSidebar()
        }

        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    buildTieredPrices() {
      return this.applySafeNumberConfigurationTo(this.tieredPrices.map(tieredPrice => {
        const { quantityFrom, quantityTo, price, id, isDeleted = false } = tieredPrice || {}
        return {
          id,
          storeId: this.itemForm.storeId,
          priceTableId: this.itemForm.priceTableId,
          quantityFrom,
          quantityTo,
          price,
          problematic: quantityFrom >= quantityTo,
          isDeleted
        }
      }))
    },

    getProblematicPriceRanges(tieredPrices) {
      return tieredPrices
        .map((price, index) => (price.problematic ? index : null))
        .filter(index => index !== null)
    },

    showWarningForProblematicRanges(problematicPriceRanges) {
      const warningMessage = this.buildFormattedWaningMessage(problematicPriceRanges)
      this.showWarning({ message: this.$t(warningMessage) })
    },

    buildFormattedWaningMessage(problematicPriceRanges) {
      const warningMessage = `
        ${problematicPriceRanges.length > 1 ? 'As faixas' : 'A faixa'} ${
          this.formatWarningRange(problematicPriceRanges)
        } ${problematicPriceRanges.length > 1 ? 'estão' : 'está'} com a quantidade inicial maior ou igual que a quantidade final.
      `

      return warningMessage
    },

    formatWarningRange(ranges) {
      if (ranges.length === 1) return ranges[0] + 1

      const allButLast = ranges.slice(0, -1).map(range => range + 1).join(', ')
      const last = ranges[ranges.length - 1] + 1

      return `${allButLast} e ${last}`
    },

    getQuantityFrom(index) {
      if (index === 0) return 1
      return (this.tieredPrices[index - 1]?.quantityTo ?? 0) + 1
    },

    updateNextQuantityFrom(index) {
      if (index < this.tieredPrices.length - 1) {
        this.tieredPrices[index + 1].quantityFrom = this.tieredPrices[index].quantityTo + 1
      }
    },

    validatePrice(index) {
      if(index <= 0) return true

      const previousPrice = this.tieredPrices[index - 1]?.price
      const currentPrice = this.tieredPrices[index]?.price

      if(currentPrice <= previousPrice) return true

      // Se o preço atual for maior que o anterior, defina como o mesmo valor do anterior
      this.tieredPrices[index].price = previousPrice
      this.showWarning({ message: 'O preço não pode ser maior que o do item anterior.' })

      return false
    },
    handleRemove(item) {
      const safeObject = this.applySafeNumberConfigurationTo(item)
      this.removedPrices.push(safeObject)

      item.forEach((_, index) => {
        this.updateNextQuantityFrom(index)
      })

      this.updateTieredPrices()
    },

    updateTieredPrices() {
      const updatedTieredPriceIds = this.tieredPrices.map(tieredPrice => tieredPrice.id)
      const pricesToRemove = this.removedPrices
        .map(removedPrice =>
          removedPrice.filter(price => !updatedTieredPriceIds.includes(price.id))
        )
        .flat()
        .map(priceToRemove => ({
          ...priceToRemove,
          isDeleted: true
        }))

      const tieredPrices = [
        ...this.tieredPrices,
        ...pricesToRemove
      ]

      this.setTieredPrice(tieredPrices)
    },

    applySafeNumberConfigurationTo(arrayOfObject) {
      const safeArrayOfObject = []

      arrayOfObject.forEach(object => {
        const updatedObject = {
          ...object,
          quantityTo: this.updateUnsafeNumberForSafeNumber(object.quantityTo),
          quantityFrom: this.updateUnsafeNumberForSafeNumber(object.quantityFrom),
          safe: true
        }
        safeArrayOfObject.push(updatedObject)
      })

      return safeArrayOfObject
    },

    updateUnsafeNumberForSafeNumber(number) {
      let numberToSafe = number
      if(!Number.isSafeInteger(number)) numberToSafe = SAFE_JAVASCRIPT_MAX_INTEGER_VALUE ||  SAFE_MAX_INPUT_VALUE
      if(number >= SAFE_MAX_INPUT_VALUE) numberToSafe = SAFE_MAX_INPUT_VALUE

      return numberToSafe
    }
  },
}
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: 800;
}
</style>
